// .c-sidebar:not(.c-dark-theme)  {
//     color: #fff;
//     background: #2d333d;
// }

// .c-dark-theme .c-sidebar {
//     color: #fff;
//     // background: #2d333d;
// }

// $light-background: #414042;
// $light-text: #fff;

// @mixin theme-colors($light-theme: true) {
//     @if $light-theme {
//         background-color: $light-background;
//         color: $light-text;
//     }
// }

// .c-sidebar {
//     @include theme-colors($light-theme: true);
//     c-dark-theme & {
//       @include theme-colors($light-theme: false);
//     }
// }

.c-sidebar-nav-link {
    &.c-active {
        background-color: #fa761b !important;
    }
}